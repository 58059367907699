import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import cimg from '../../images/wc/337645847_727275822225109_2115871580902218819_n.jpg'
import cimg2 from '../../images/contact.png'
import VideoModal from '../ModalVideo/VideoModal';


const Appointment = () => {

    function makeContact(formData) {
        let object = {};
        formData.forEach(function(value, key){
            object[key] = value;
        });
        const json = JSON.stringify(object);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'https://teste.wcportateis.pt',
                'Access-Control-Request-Method': 'POST',
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
            body: json
        };
        fetch('https://api.wcportateis.pt/wc/messaging', requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    console.log(error);
                    alert("Não foi possível efetuar o pedido de contacto.\nPor favor, tente novamente mais tarde, ou contacte-nos por telefone.");
                } else {
                    alert("Pedido de contacto efetuado com sucesso.\nResponderemos o mais breve possível.");
                }
            })
            .catch(error => {
                this.setState({errorMessage: error.toString()});
                console.error('There was an error!', error);
            });
    }

    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            const formData = new FormData(e.target);
            makeContact(formData);

            setForms({
                name: '',
                email: '',
                subject: '',
                phone: '',
                message: ''
            })
        } else {
            validator.showMessages();
        }
    };


    return (
        <section className="wpo-contact-section section-padding">
            <div className="wpo-contact-img">
                <img src={cimg} alt="" />
            </div>
            <div className="wpo-contact-img-s2">
                <img src={cimg2} alt="" />
            </div>
            <div className="container">
                <div className="wpo-contact-section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-lg-8 col-md-12 col-12">
                            <div className="wpo-contact-form-area">
                                <div className="wpo-section-title-s2">
                                    <span>Contacte-nos</span>
                                    <h2>Formulário de Contacto</h2>
                                </div>
                                <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
                                    <div className="row">
                                        <div className="col col-lg-6 col-12">
                                            <div className="form-group">
                                                <label>Nome*</label>
                                                <input
                                                    className="form-control"
                                                    value={forms.name}
                                                    type="text"
                                                    name="name"
                                                    onBlur={(e) => changeHandler(e)}
                                                    onChange={(e) => changeHandler(e)}
                                                    placeholder="Nome" />
                                            {validator.message('name', forms.name, 'required')}
                                            </div>
                                        </div>
                                        <div className="col col-lg-6 col-12">
                                            <div className="form-group">
                                                <label>Email*</label>
                                                <input
                                                    className="form-control"
                                                    value={forms.email}
                                                    type="email"
                                                    name="email"
                                                    onBlur={(e) => changeHandler(e)}
                                                    onChange={(e) => changeHandler(e)}
                                                    placeholder="Email" />
                                                {validator.message('email', forms.email, 'required|email')}
                                            </div>
                                        </div>
                                        <div className="col col-lg-6 col-12">
                                            <div className="form-group">
                                                <label>Contacto Telefónico</label>
                                                <input
                                                    className="form-control"
                                                    value={forms.phone}
                                                    type="phone"
                                                    name="phone"
                                                    onBlur={(e) => changeHandler(e)}
                                                    onChange={(e) => changeHandler(e)}
                                                    placeholder="Contacto" />
                                            </div>
                                        </div>
                                        <div className="col col-lg-6 col-12">
                                            <div className="form-group">
                                                <label>Assunto*</label>
                                                <select className="form-control"
                                                    onBlur={(e) => changeHandler(e)}
                                                    onChange={(e) => changeHandler(e)}
                                                    value={forms.subject}
                                                    type="text"
                                                    name="subject">
                                                    <option>Qual o assunto?</option>
                                                    <option>Pedido de Orçamento</option>
                                                    <option>Pedido de Informação</option>
                                                    <option>Reclamação</option>
                                                    <option>Outros Assuntos</option>
                                                </select>
                                                {validator.message('subject', forms.subject, 'required')}
                                            </div>
                                        </div>
                                        <div className="col fullwidth col-lg-12 ">
                                            <div className="form-group">
                                                <textarea
                                                    className="form-control"
                                                    onBlur={(e) => changeHandler(e)}
                                                    onChange={(e) => changeHandler(e)}
                                                    value={forms.message}
                                                    type="text"
                                                    name="message"
                                                    placeholder="Mensagem">
                                                </textarea>
                                                {validator.message('message', forms.message, 'required')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="submit-area">
                                        <button type="submit" className="theme-btn">Enviar Pedido de Contacto</button>
                                    </div>
                                </form>
                                <div className="border-style"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Appointment;