import simg from '../images/service/1.jpg'
import simg2 from '../images/service/2.jpg'
import simg3 from '../images/service/3.jpg'
import simg4 from '../images/service/4.jpg'
import simg5 from '../images/service/5.jpg'
import simg6 from '../images/service/6.jpg'

import sIcon1 from '../images/icon/kitchen-utensils.png'
import sIcon2 from '../images/icon/gas-stove.png'
import sIcon3 from '../images/icon/water-tap.png'
import sIcon4 from '../images/icon/sink.png'
import sIcon5 from '../images/icon/basement.png'
import sIcon6 from '../images/icon/remodeling.png'

import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'

import sWcMini from '../images/service-single/wc/mini.jpg'
import wcVip from '../images/wc/wcvip.png'
import wcStandard from '../images/wc/standard.png'
import wcMobility from '../images/wc/mobilidade.png'
import wcUri from '../images/wc/uri.png'
import wcLM from '../images/wc/lavamaos.png'
import wcLMInox from '../images/wc/lavamaosinox.png'
import wcContentor from '../images/wc/contentor.png'

const Services = [
    {
        Id: '1',
        sImg:wcStandard,
        sIcon:wcStandard,
        sTitle: 'WC Portáteis Standard',
        description:'Equipamentos standard para eventos e obras com urinól e wc.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '2',
        sImg:wcVip,
        sIcon:wcVip,
        sTitle: 'WC Portáteis VIP',
        description:'Equipamentos com urinól, wc e lava mãos.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        sImg:sWcMini,
        sImgSmall:sWcMini,
        sIcon:sIcon3,
        sTitle: 'Mini-Cabines',
        description:'Solução mais fácil de integrar em sitíos pequenos ou apertados, wc e lava mãos.'
    },
    {
        Id: '4',
        sImg:wcMobility,
        sIcon:wcMobility,
        sTitle: 'WC Portáteis - Mobilidade Reduzida',
        description:'Equipamentos aptos para mobilidade reduzida.',
        bigText: "",
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '5',
        sImg:wcUri,
        sIcon:wcUri,
        sTitle: 'Urinóis Quádruplos',
        description:'Urinóis com lotação para 4 pessoas.',
        bigText: "",
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '6',
        sImg:wcLM,
        sIcon:wcLM,
        sTitle: 'Lava Mãos',
        description:'Lava Mãos equipado com dispensador de sabão liquido e papel.',
        bigText: "",
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '7',
        sImg:wcLMInox,
        sIcon:wcLMInox,
        sTitle: 'Lava Mãos (INOX)',
        description:'Lava Mãos em INOX equipado com dispensador de sabão liquido e papel.',
        bigText: "",
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '8',
        sImg:wcContentor,
        sIcon:wcContentor,
        sTitle: 'Contentor 2 WC\'s',
        description:'Contentor com 2 wc\'s individuais equipados com wc, urinól e lava mãos.',
        bigText: "",
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    }
]
export default Services;