import avatar from '../images/avatar.jpg'
import avatarFemale from '../images/avatar-female.jpg'


const Team = [
    {
       Id:'1',
       tImg:avatar,
       name:'Luís Silvestre',
       title:'Sócio-Gerente',
    },
    {
       Id:'2',
       tImg:avatarFemale,
       name:'Nadine Ferreira',
       title:'Gestora de Clientes',
    },
    {
       Id:'3',
       tImg:avatarFemale,
       name:'Rita Henriques',
       title:'Backoffice',
    },
    {
       Id:'4',
       tImg:avatarFemale,
       name:'Verónica Mulkowski',
       title:'Backoffice',
    },
    {
        Id:'4',
        tImg:avatar,
        name:'António Barbosa',
        title:'Funcionário',
    },
    {
        Id:'6',
        tImg:avatar,
        name:'Bruno Jesus',
        title:'Funcionário',
    },
    {
        Id:'7',
        tImg:avatar,
        name:'Carlos Braz',
        title:'Funcionário',
    },
    {
        Id:'8',
        tImg:avatar,
        name:'Daniel Santos',
        title:'Funcionário',
    },
    {
        Id:'9',
        tImg:avatar,
        name:'Daniel Silva',
        title:'Funcionário',
    },
    {
        Id:'10',
        tImg:avatar,
        name:'Paulo Santos',
        title:'Funcionário',
    },
    {
        Id:'11',
        tImg:avatar,
        name:'Vítor Paquete',
        title:'Funcionário',
    }
    
]

export default Team;