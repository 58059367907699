import React from 'react'
import abimg from '../../images/wc/luis_silvestre.jpg'

const About = (props) => {
    return(
        <section className="wpo-about-section section-padding">
            <div className="container">
                <div className="wpo-about-section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12 col-12">
                            <div className="wpo-about-img">
                                <img src={abimg} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12">
                            <div className="wpo-about-content">
                                <div className="wpo-section-title-s2">
                                    <h2>Uma empresa com 16 anos de história</h2>
                                </div>
                                <div className="wpo-about-content-inner">
                                    <p>A empresa "WC Portáteis" foi fundada a 8 de Janeiro de 2007 por Luís Abílio Costa Silvestre, como sendo uma empresa em nome individual, actualmente somos Uma Sociedade Unipessoal, Lda .</p>
                                    <p>Durante o primeiro ano de actividade, a empresa concentrou-se em angariar novos clientes, e a marcar a sua presença no mercado, através de trabalhos que os nossos estimados clientes e amigos nos foram confiando, e assim ao longo dos anos fomos progredindo no mercado.</p>
                                    <p>A nossa actividade está vocacionada para o aluguer e manutenção de sanitários portáteis, despejo de Fossas e Desentupimentos</p>
                                    <div className="signeture">
                                        <h4>Luís Silvestre</h4>
                                        <p>Sócio-Gerente</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;